import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Castle from '../images/new/slider/castle.svg'
import Medals from '../images/new/slider/medals.svg'
import Rocket from '../images/new/slider/rocket.svg'
import Robot from '../images/new/slider/robot.svg'

class VideosCarousel extends React.Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      autoplaySpeed: 6500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            dots: false
          }
        }
      ]
    }
    
    return (
      <Fragment>
        <Slider {...settings}>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Castle} alt='' />
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Medals} alt='' />
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Rocket} alt='' />
            </div>
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={Robot} alt='' />
            </div>
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default VideosCarousel