import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import VideosCarousel from '../components/VideosCarousel'
import Teste2 from '../components/Teste'
import Banner from '../images/new/banners/nossa-historia.png'
import Cloud from '../images/new/slider/cloud.svg'
import Dots from '../images/new/slider/dots.svg'

import { 
  section3,
  container,
  container2,
  content,
  columns,
  column,
  desktop,
  sliderDesktop,
  mobile,
  sliderSection,
  dotsImage
} from '../components/styles/Base.styles'

const NossosPilares = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Nossa História | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={[section3, content]}>
      <div css={container2}>
        <div css={columns}>
          <div css={column} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'center',  }}>
              <img  css={mobile}src={Banner} alt='' style={{ marginTop: 68, width: '70%' }}  />
            </div>
            <h1>Nossa História</h1>
            <p>Nós sonhamos e, por sonharmos, existe hoje a Kingdom Kids e Kingdom School. E é por um sonho que todos nós estamos aqui!</p>
            <p>A Kingdom nasceu em 2010, com um ideal de ajudar pessoas, colaborar para uma educação mais humana, dinâmica, amorosa e efetiva.</p>
            <p>Em 10 anos de história, a nossa escola cresceu e tornou-se uma referência no âmbito educacional, resultado do nosso comprometimento com a cidadania mundial na formação das nossas crianças, estimulando-as a enfrentar os desafios da vida, sempre visando ao conhecimento, sabedoria e dignidade, de forma ativa, participativa e competente.</p>
            <p>O amor e acolhimento são as marcas da nossa escola, e é um prazer receber você e compartilhar um pouco da nossa história.</p>
          </div>
          <div css={column}>
            <div style={{ display: 'flex', justifyContent: 'end',  }}>
              <img css={desktop} src={Banner} alt='' style={{ marginTop: 0, textAlign: 'center', marginBottom: 0 }}  />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div css={sliderSection}>
      <div style={{ marginBottom: 24, textAlign: 'end' }}>
        <img css={dotsImage} src={Dots} alt='' />
      </div>
      <div css={container2} style={{ marginBottom: '-136px' }}>
        <VideosCarousel />
      </div>
      <div css={[container, sliderDesktop]}>
        <Teste2 />
      </div>
      <div style={{ marginTop: '-158px', marginBottom: '-3px' }}>
        <img src={Cloud} alt='' style={{ width: '100%' }}  />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default NossosPilares
