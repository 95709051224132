import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import { 
  contentSlider
} from '../components/styles/Base.styles'
class VideosCarousel extends React.Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      speed: 700,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            dots: false
          }
        }
      ]
    }
    
    return (
      <Fragment>
        <Slider {...settings}>
          <div>
            <div css={contentSlider}>
              <h2>2010</h2>
              <h3>O início de um sonho</h3>
              <p>Início de uma grande jornada Em Brasília, no bairro Lago Sul, existiu uma oportunidade de negócio com a empresa APS (Academia do Personal Self), que trazia um desenvolvimento humano e pessoal aos adultos.</p>
            </div>
          </div>
          <div>
            <div css={contentSlider}>
              <h2>2010</h2>
              <h3>A Kingdom nasceu como o nome "Bebê Total"</h3>
              <p>Com intuito de oferecer serviços compatíveis e que fossem extensivos às crianças, foi criado um programa que dava vida a um conceito educacional: a criança era recebida juntamente com seu responsável e, durante 2 horas, participava de atividades de estimulação, como música, psicomotricidade e artes.</p>
            </div>
          </div>
          <div>
            <div css={contentSlider}>
              <h2>2011</h2>
              <h3>Surge a Kingdom Kids</h3>
              <p>Devido a grande demanda, expandimos nossos serviços e nos tornamos uma escola, a Kingdom Kids. No início do segundo semestre deste ano, recebemos nosso primeiro aluno de berçário e ele foi o pontapé inicial para um novo tempo. A partir disso, o que eram 3 turmas viraram 8 em menos de 12 meses. Tivemos de fazer uma grande expansão na estrutura.</p>
            </div>
          </div>
          <div>
            <div css={contentSlider}>
              <h2>2012</h2>
              <h3>Momento de consolidação</h3>
              <p>A escola fez sua primeira expansão e abriu o dobro de salas para comportar a quantidade de alunos que desejavam estar ali. Desde então, a escola fez sucessivas reformas de ampliação até 2019.</p>
            </div>
          </div>
          <div>
            <div css={contentSlider}>
              <h2>2018</h2>
              <h3>O desejo de expansão</h3>
              <p>Para dar continuidade ao ensino dos nossos alunos e atender a alta procura pela filosofia Kingdom, a ideia de expandir para uma nova unidade veio de forma natural. Muitos pais que gostam e entendem a importância da metodologia da Kingdom Kids na vida de seus filhos, expressavam o desejo de querer continuar conosco nos Anos Iniciais do Ensino Fundamental.</p>
            </div>
          </div>
          <div>
            <div css={contentSlider}>
              <h2>2019 e 2020</h2>
              <h3>O período de desafios</h3>
              <p>Em 2019, nos empenhamos fortemente para que acontecesse a expansão em 2020, mas não achávamos que era o momento ideal devido às barreiras externas.</p>
            </div>
          </div>
          <div>
            <div css={contentSlider}>
              <h2>2021</h2>
              <h3>A realização e o início da Kingdom School</h3>
              <p>Achamos o local ideal para receber nossos alunos com a mesma qualidade e eficiência que a Kingdom Kids sempre possuiu. A nova unidade fica a poucos minutos do espaço já existente, no Lago Sul, e trará comodidade aos pais que tiverem filhos nas duas escolas.</p>
            </div>
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default VideosCarousel